<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="订单号">
          <el-input style="width:220px" v-model="searchData.orderNo" size="mini" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="资方名称">
          <el-input style="width:220px" v-model="searchData.capitalName" size="mini" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="资方手机号">
          <el-input style="width:220px" v-model="searchData.capitalMobile" size="mini" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select style="width:220px" v-model="orderStatusList" placeholder="请选择" size="mini" multiple clearable>
            <el-option v-for="item in orderStatus3" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期">
          <el-date-picker
            style="width:220px"
            v-model="timeSlot"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="认领日期">
          <el-date-picker
            style="width:220px"
            v-model="timeSlot_renling"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList()">搜索</el-button>
          <el-button type="info" icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="head-btn">
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.capital_bill_list_export)"
        type="primary"
        icon="el-icon-download"
        size="mini"
        @click="handleExport"
        >导出</el-button
      >
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column type="index" label="序号" :index="indexMethod" width="80"> </el-table-column>
      <el-table-column prop="amountType" label="用户信息" min-width="170">
        <template slot-scope="{ row }">
          <div v-if="row['member']">
            <div>姓名：{{ row['member']['memberName'] }}</div>
            <div>手机号：{{ row['member']['mobile'] }}</div>
            <div>
              用户类型：
              <el-tag v-if="row['memberType'] == 1" size="small" type="success">
                新客
              </el-tag>
              <el-tag v-show="row['memberType'] == 2" size="small" type="primary">
                在租
              </el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="商品信息" min-width="180">
        <template slot-scope="{ row }">
          <div>
            <div>{{ row.goodsTitle }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="billTypeName" label="订单信息" min-width="280">
        <template slot-scope="{ row }">
          <div>
            <div>订单单号：{{ row['orderNo'] }}</div>
            <div>下单时间：{{ row['orderCreateTime'] }}</div>
            <div>
              订单状态：
              <el-tag type="primary" size="mini">{{ row['orderStatusName'] }}</el-tag>
            </div>
            <div>
              订单类型：
              <el-tag v-if="row['orderType'] == 1" type="primary" size="mini">
                普通订单
              </el-tag>
              <el-tag v-if="row['orderType'] == 2" type="warning" size="mini">
                门店订单
              </el-tag>
              <el-tag v-if="row['orderType'] == 3" type="success" size="mini">
                卡券订单
              </el-tag>
              <el-tag v-if="row['orderType'] == 4" color="#ffcccc54" style="color:#ff7744;border-color:#ffcccc54" size="mini">
              电车订单
              </el-tag>
              <el-tag v-if="row['enableAlipayFundAuth'] == true" type="danger" size="mini" style="margin-left: 5px">
                免押
              </el-tag>
            </div>

            <div v-if="row['deliveryStatus'] == 10">
              待发货类型：
              <el-tag type="primary" size="mini">
                全部待发货
              </el-tag>
            </div>
            <div v-if="row['deliveryStatus'] == 11">
              待发货类型：
              <el-tag type="warning" size="mini">
                实物待发货
              </el-tag>
            </div>
            <div v-if="row['deliveryStatus'] == 12">
              待发货类型：
              <el-tag type="success" size="mini">
                虚拟待发货
              </el-tag>
            </div>

            <div>下单方式：{{ row['clientTypeName'] }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="capitalName" label="资方归属" min-width="250">
        <template slot-scope="{ row }">
          <div>
            <div class="row">
              <span>资方名称：{{ row.capitalName }}</span>
            </div>
            <div class="row">
              <span>联系方式：{{ row.capitalMobile }}</span>
            </div>
            <div class="row">
              <span>认领时间：{{ row.createTime }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="还款计划" min-width="190">
        <template slot-scope="{ row }">
          <div>
            <div class="row">
              <span>待收期数：{{ row.leftBillNum }}</span>
            </div>
            <div class="row">
              <span>最近还款日：{{ row.latestBillDate }}</span>
            </div>
            <div class="row">
              <span>最近待收金额：{{ row.latestBillAmount }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="acquireAmount" label="订单价值" min-width="120"> </el-table-column>
      <el-table-column prop="totalDueAmount" label="待收账单" min-width="120"> </el-table-column>
      <el-table-column prop="repayBillAmount" label="已回收金额" min-width="120"> </el-table-column>
      <el-table-column prop="leftDueAmount" label="剩余待收金额" min-width="120"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="{ row }">
          <div>
            <el-link
                style="margin-right:10px;"
                type="primary"
                :underline="false"
                :disabled="!isShowBtn(AUTH_BTN.capital_order_list_detail)"
                @click="handleOrderDetail(row)"
            >详情
            </el-link>
            <el-link
                style="margin-right:10px;"
                type="primary"
                :underline="false"
                :disabled="!isShowBtn(AUTH_BTN.capital_order_list_bill)"
                @click="handleOrderBill(row)"
            >账单
            </el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" />

    <div style="height:100px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, DownloadAPI } from './api'
import { orderStatus3 } from '@/enum/dict.js'
export default {
  name: 'List',
  mixins: [authBtnMixin],

  data() {
    return {
      total: 0,
      list: [],
      searchData: {
        page: 1,
        pageSize: 10,
        orderNo: '',
        capitalName: '',
        capitalMobile: ''
      },
      timeSlot: [],
      timeSlot_renling: [],
      orderStatusList: [],
      orderStatus3 //字典
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot, timeSlot_renling, orderStatusList } = this
      let params = { ...this.searchData }
      if (timeSlot && timeSlot.length > 0) {
        params.leftOrderCreateDate = timeSlot[0]
        params.rightOrderCreateDate = timeSlot[1]
      }
      if (timeSlot_renling && timeSlot_renling.length > 0) {
        params.leftCreateDate = timeSlot_renling[0]
        params.rightCreateDate = timeSlot_renling[1]
      }
      if (orderStatusList && orderStatusList.length > 0) {
        params.orderStatusList = orderStatusList.join(',')
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    reset() {
      this.timeSlot = []
      this.timeSlot_renling = []
      this.orderStatusList = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },

    handleExport() {
      const { capitalName, capitalMobile, orderNo } = this.searchData
      const { timeSlot, timeSlot_renling, orderStatusList } = this
      let params = { capitalName, capitalMobile, orderNo }
      if (timeSlot && timeSlot.length > 0) {
        params.leftOrderCreateDate = timeSlot[0]
        params.rightOrderCreateDate = timeSlot[1]
      }
      if (timeSlot_renling && timeSlot_renling.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      if (orderStatusList && orderStatusList.length > 0) {
        params.orderStatusList = orderStatusList.join(',')
      }
      DownloadAPI(params)
    },
    // 序号自增--方法
    indexMethod(index) {
      // 拿到页码
      const currentPage = this.searchData.page
      // 拿到每页记录条数
      const pageSize = this.searchData.pageSize
      // 根据值进行计算返回
      return index + 1 + (currentPage - 1) * pageSize
    },
    handleOrderDetail(row) {
      let link = this.$router.resolve({ name: `OrderDetail`, params: { id: row.orderNo }, query: { memberId: row.member.id } })
      window.open(link.href, '_blank')
    },
    handleOrderBill(row) {
      let link = this.$router.resolve({ name: `OrderBill`, params: { id: row.orderNo } })
      window.open(link.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  .el-divider--horizontal {
    margin: 10px 0 20px;
  }
  .bill-type {
    .el-radio-button__inner {
      padding: 7px 24px;
    }
  }
  .head-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-form {
    .el-form-item {
      margin-right: 25px;
      margin-bottom: 0px;
    }
  }
  .captial-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    .row {
      display: flex;
      align-items: center;
      margin-right: 40px;
      .label {
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .value {
        font-weight: 600;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
